import { createSlice } from '@reduxjs/toolkit'

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    show: true,
    unfoldable: false,
  },
  reducers: {
    updateShow: (state, payload) => {
      console.log(payload)
      state.show = !state.show
    },
    updateFlodable: (state, payload) => {
      console.log(payload)
      state.unfoldable = payload['payload']
    },
  },
})

export const { updateShow, updateFlodable } = sidebarSlice.actions
export default sidebarSlice.reducer
